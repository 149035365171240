<template>
  <div>
    <Navbar></Navbar>
    <span class="checkmark">
      <div class="checkmark_circle">
        <div class="checkmark_stem"><div class="checkmark_kick"></div></div>
      </div>
    </span>
    <div style="margin-top: 50px; margin: auto; width: 85%">
      <h2>success</h2>
      <h3>
        In order to activate the QR code, please share the link below with your
        renter.
      </h3>
      <div class="flexContainer">
        <input
          v-on:focus="$event.target.select()"
          ref="myinput"
          readonly
          :value="text"
        /><button class="button copy" @click="copy">Copy</button>
      </div>

      <div></div>
      <button
        class="button"
        @click="shareViaWebShare"
        style="margin-top: 125px"
      >
        Share
      </button>
      <div></div>
      <button class="button done" @click="done" style="margin-top: 60px">
        Done
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "../components/Navbar.vue";
import inputElements from "@/components/Input.vue";

export default {
  name: "ShareUrl",
  components: {
    Navbar,
    inputElements,
  },
  data() {
    return {
      text: this.$store.getters["rent/getRegisterUrl"],
    };
  },
  methods: {
    shareViaWebShare() {
      navigator
        .share({
          title: "Register",
          text: "Register link",
          url: this.$store.getters["rent/getRegisterUrl"],
        })
        .then((response) => {
          console.log("remove token");
        });
    },
    copy() {
      this.$refs.myinput.focus();
      document.execCommand("copy");
    },
    done() {
      this.$router.push({ name: "GetUrl" });
    },
  },
};
</script>

<style>
h2 {
  margin-top: 40px;
  color: #0063e0;
}

h3 {
  color: #646363;
  font-size: 1em;
}

.title-content {
  margin-top: 50px;
  margin-bottom: 50px;
}

.checkmark {
  display: inline-block;
  width: 100px;
  height: 100px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_circle {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: #2ecc71;
  border-radius: 50px;
  left: 0;
  top: 0;
}

.checkmark_stem {
  position: absolute;
  width: 10px;
  height: 47px;
  background-color: #fff;
  left: 50px;
  top: 17px;
}

.checkmark_kick {
  position: absolute;
  width: 25px;
  height: 10px;
  background-color: #fff;
  left: -15px;
  top: 44px;
}

.flexContainer {
  display: flex;
  height: 34px;
}

.inputField {
  flex: 1;
}

.button.copy {
  width: 80px;
  height: 34px;
  margin-top: 0;
}

.button.done {
  background-color: #3f4855;
  width: 130px;
}
</style>