<template>
  <div>
    <div v-if="login">
      <input
        :class="classname"
        :id="id"
        :type="type"
        :placeholder="placeholder"
        v-model="inputValue"
      />
    </div>
    <div v-else>
      <label :for="id" class="label-name">
        <h3>
            {{ label }}
        </h3>
      </label>
      <input
        class="input-box"
        :id="id"
        :type="type"
        :placeholder="placeholder"
        v-model="inputValue"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    id: {
      type: String,
      required: true,
      default: "input",
      note: "Id for the input",
    },
    type: {
      type: String,
      required: true,
      default: "text",
      note: "Type for the input",
    },
    label: {
      type: String,
      required: false,
      default: "input label",
      note: "Label for the input",
    },
    value: {
      type: [String, Number, Date],
      required: false,
      default: "",
      note: "Value for the input",
    },
    isEmpty: {
      type: String,
      required: false,
      default: "true",
      note: "Attribute for the input",
    },
    placeholder: {
      type: String,
      require: false,
      default: "",
      note: "Placeholder text",
    },
    login: {
      type: Boolean,
      required: false,
      default: false,
      note: "Login input",
    },
    classname: {
      type: String,
      required: false,
      default: "",
      note: "CSS classname",
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.label-name {
  float: left;
  margin-left: 10px;
  margin-bottom: -25px;
  color: #003168;
}
input.input-box {
  border-bottom: 1px solid #dadada;
}
input.username {
  background-image: url("../assets/mail_icon.png");
  padding-left: 40px;
}
input.pass {
  background-image: url("../assets/lock_icon.png");
  padding-left: 40px;
}

</style>
