<template>
    <div id="nav">
      <img alt="Vue logo" src="../assets/logo2.png">
    </div>
</template>


<script>
// @ is an alias to /src
export default {
  name: 'Navbar'
}
</script>

<style>
#nav {
  height: 50px;
  box-shadow: 0 4px 10px -8px gray;
  margin-bottom: 5%;
}
img {
  padding: center;
  margin-top: 3%;
}
</style>
