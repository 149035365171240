import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'
import Geturl from '@/views/GetUrl.vue'
import ShareUrl from '@/views/ShareUrl.vue'
import store from '../store'
Vue.use(VueRouter)

function load(component) {
    return () =>
        import(`@/views/${component}.vue`)
}

const routes = [{
    path: '/',
    name: 'Login',
    component: Login
},
{
    path: '/geturl',
    name: 'GetUrl',
    component: Geturl
},
{
    path: '/shareurl',
    name: 'ShareUrl',
    component: ShareUrl
}
]

const router = new VueRouter({
    //mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'Login' && !store.getters['login/loggedIn']) next({ name: 'Login' })
    else next()
})

export default router;