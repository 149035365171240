import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import './assets/style/style.css'

Vue.config.productionTip = false

axios.interceptors.request.use(
  async (config) => {
    const token = await store.getters["login/getAccessToken"]
    if (token) {
      config.headers.Authorization = token
    }

    return config
  }
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
